<template>
  <div id="app">
    <img alt="Mediaflow logo" src="https://mediaflow-config.s3-ap-southeast-2.amazonaws.com/mf/mediaflow-6png300.png" class="mfLogo">
    <HelloWorld msg="COMING SOON"/>
    <a href="mailto:info@mediaflow.co.nz">info@mediaflow.co.nz</a>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 10vh;
  height: 90vh;
  width: 99vw;
}
.mfLogo {
  width: 23em;
}

body {
  background-image: linear-gradient(to bottom,black, #2e2e2e);
  padding: 0;
  margin: 0;
}
a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color:rgb(165, 31, 51);
}

</style>
